import { forwardRef, useState } from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { styled, css, Box } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

export const VideoModal = ({ sx = {}, src, label, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={sx}>
      <Chip
        label={label || 'Reproducir video tutorial'}
        onClick={handleOpen}
        icon={<YouTubeIcon />}
        sx={{
          '&.MuiChip-root': {
            display: 'flex',
            gap: '5px',
            width: 'max-content',
            paddingLeft: '5px',
            paddingRight: '5px',
            '& svg': {
              color: '#FF0033',
            },
          },
          '&.MuiChip-root:hover': {
            '& svg': {
              color: '#df002c',
            },
          },
        }}
      />
      <Modal
        aria-labelledby='unstyled-modal-title'
        aria-describedby='unstyled-modal-description'
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={{ width: '100%', height: '100%' }}>
          <IconButton
            aria-label='fingerprint'
            color='primary'
            className='modal-close'
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            src={src}
            title='Video tutorial'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
            autoFocus
          ></iframe>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const Backdrop = forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ 'base-Backdrop-open': open }, className)} ref={ref} {...other} />;
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.75);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled('div')(
  ({ theme }) => css`
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }

    & iframe {
      width: 60%;
      height: 60%;
      border-width: 0;
      border-radius: 8px;
      ${theme.breakpoints.down('sm')} {
        width: 90%;
        height: 70%;
      }
    }

    & .modal-close {
      position: absolute;
      right: 15px;
      top: 15px;
      outline: none;

      svg {
        font-size: 40px;
        padding: 7px;
        background: #ffffffd4;
        color: #037871;
        border-radius: 50%;

        &:hover {
          background: #e5e5e5d4;
          transition: background 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }
    }
  `,
);
